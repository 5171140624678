<template>

  <div class="common-wrapper">
    <div class="spin" v-if="loading">
      <a-spin size="large" />
    </div>
    <header class="common-wrapper-header">
      <div>
        <div class="title">
          <span>{{ data.ruleName }}</span>
          <form-outlined @click="showModal(null)" style="padding-left: 8px; color: #666666; cursor: pointer" />
        </div>
        <div class="content">
          <span>{{ data.id }}</span>
          <a-divider type="vertical" style="height: 12px; background-color: #ebebeb" />
          <span>{{ data.description }}</span>
        </div>
      </div>
      <div v-if="data.ruleStatus == '01'">
        <a-button @click="changeStatus('ruleDisabled')">停用</a-button>
      </div>
      <div v-else>
        <a-button type="primary" style="margin-right: 8px" @click="handleSave">保存</a-button>
        <a-button @click="changeStatus('ruleEnable')">启用</a-button>
      </div>
    </header>
    <a-divider style="height: 1px; background-color: #ebebeb" />
    <div style="padding-left: 10px">
      <div class="node">
        <div class="title">推送条件</div>
        <div class="content">
          <div>
            <a-input value="项目条件" disabled />
            <a-select v-model:value="pushCondition.selectAllProject" placeholder="请选择项目类型"
              :options="options.selectAllProject" :disabled="ruleStop" @change="changeProjectType">
            </a-select>
          </div>
          <a-select v-if="!pushCondition.selectAllProject" v-model:value="pushCondition.bizProjectIdList"
            mode="multiple" style="width: 100%; margin-top: 12px" :disabled="ruleStop" placeholder="请选择项目">
            <a-select-option v-for="project in options.bizProjectIdList" :key="project.bizProjectId"
              :value="project.bizProjectId">{{ project.name }}</a-select-option>
          </a-select>
        </div>
        <div v-for="(condition, idx) in conditionList" :key="idx">
          <div class="content">
            <div style="display: flex;">
              <a-select v-model:value="condition.type" placeholder="请选择告警条件" :disabled="ruleStop"
                @change="changeConditionType(condition)">
                <a-select-option v-for="option in options.type" :key="option.value" :value="option.value"
                  :disabled="isOptionDisabled(option.value, condition.type)">
                  {{ option.label }}
                </a-select-option>
              </a-select>
              <a-select style="flex: 1;" v-model:value="condition.data" placeholder="请选择告警条件值" mode="multiple"
                :disabled="ruleStop" :options="options[condition.type]">
              </a-select>
            </div>
            <div class="delete-btn" @click="deleteCondition(idx)">-</div>
          </div>
        </div>
        <div>
          <a-button type="link" @click="addCondition"> + 增加推送条件</a-button>
        </div>
      </div>
      <div class="node">
        <div class="title">推送方式</div>
        <div class="content" v-for="(item, idx) in pushList" :key="idx">
          <div>
            <span>执行动作：</span>
            <a-select v-model:value="item.type" :options="options.actionType" placeholder="请选择执行动作" disabled>
            </a-select>
          </div>
          <div style="display: flex;align-items: flex-end;">
            <span style="line-height: 30px;">推送用户：</span>
            <a-select style="flex: 1;" v-model:value="item.data" placeholder="请选择推送用户" mode="multiple"
              :disabled="ruleStop">
              <a-select-option v-for="option in options.pushUserList" :key="option.userId" :value="option.userId + ''">
                {{ option.nickname }}
              </a-select-option>
            </a-select>
          </div>
        </div>
      </div>
    </div>
  </div>
  <RuleEditModal v-if="modalVisible" v-model:visible="modalVisible" :detail="data" @success="editSuccess">
  </RuleEditModal>
</template>

<!-- eslint-disable no-unused-vars -->
<script setup>
import { apiAlarmPush } from "@/api/IoT/alarmPush.js";
import { useRoute } from "vue-router";
import { reactive, ref, onMounted, computed, watch } from "vue";
import RuleEditModal from "./components/RuleEditModal";
import { apiDictListByCode } from "@/api/common.js";
import { apiGateWay } from "@/api/gateway.js";
import { apiProjectDevice as apiProjectDevices } from "@/api/IoT/productManage.js";
import { apiProductDetailDeviceAttribute } from "@/api/IoT/productCenter.js";
import { cloneDeep } from "lodash";
import { message } from "ant-design-vue";
const route = useRoute();

onMounted(() => {
  initDetail();
  initOptions();
});

const data = ref({});
const options = reactive({
  selectAllProject: [{ label: '全部项目', value: true }, { label: '指定项目', value: false }],
  bizProjectIdList: [],
  type: [{ label: '告警类型', value: 'ALARM_TYPE' }, { label: '告警等级', value: 'ALARM_LEVEL' }, { label: '告警状态', value: 'ALARM_STATUS' }],
  ALARM_TYPE: [],
  ALARM_LEVEL: [],
  ALARM_STATUS: [],
  actionType: [{ label: '邮件推送', value: 0 }, { label: '短信推送', value: 1 }, { label: '钉钉推送', value: 2 }],
  pushUserList: [],
});
const loading = ref(false)

const initDetail = () => {
  apiAlarmPush.detail(route.params.id).then(({ result }) => {
    data.value = result;
    pushCondition.value = {
      selectAllProject: result.selectAllProject,
      bizProjectIdList: result.bizProjectIdList,
    }
    conditionList.value = result.conditionList
    if (result.pushList.length) {
      pushList.value = result.pushList
    }

  })
};

// 切换规则状态
const changeStatus = (func) => {
  loading.value = true
  apiAlarmPush[func](data.value.id).then(() => {
    initDetail();
  }).finally(() => {
    loading.value = false
  });
};

const ruleStop = computed(() => {
  return data.value.ruleStatus == "01"
})

const initOptions = () => {
  // 获取项目列表
  apiAlarmPush.projects().then(({ result }) => {
    options.bizProjectIdList = result;
  });
  apiDictListByCode("ALARM_TYPE").then((res) => {
    options.ALARM_TYPE = res.result;
  });
  apiDictListByCode("ALARM_LEVEL").then((res) => {
    options.ALARM_LEVEL = res.result;
  });
  apiDictListByCode("ALARM_STATUS").then((res) => {
    options.ALARM_STATUS = res.result;
  });
  apiAlarmPush.userList().then(({ result }) => {
    options.pushUserList = result;
  });
};

const resetInitValue = () => {
  // 重置 
  pushCondition.value = {
    selectAllProject: undefined,
    bizProjectIdList: [],
  }
  conditionList.value = [{ type: undefined, data: [] }]
  pushList.value = [{ type: 0, data: [] }]
}
watch(() => route.fullPath, (val) => {
  if (val.includes('IoTManage/alarmPushDetail')) {
    resetInitValue()
    initOptions()
    initDetail()
  }
})

const changeProjectType = (value) => {
  pushCondition.value.bizProjectIdList = []
}

// 推送条件
const pushCondition = ref({
  selectAllProject: undefined,
  bizProjectIdList: [],
});

const modalVisible = ref(false);
const showModal = () => {
  modalVisible.value = true;
};
const editSuccess = () => {
  initDetail()
};

// 条件节点
const conditionList = ref([{ type: undefined, data: [] }]);

const addCondition = () => {
  if (!ruleStop.value) {
    conditionList.value.push({});
  }
};
const deleteCondition = (idx) => {
  if (!ruleStop.value) {
    conditionList.value.splice(idx, 1);
  }
};
const conditionSelect = computed(() => {
  return conditionList.value.filter(e => e.type).map(e => e.type)
})

const isOptionDisabled = (value, current) => {
  return conditionSelect.value.includes(value) && value !== current;
};

// 每次切换条件类型，需要清空后面的选项
const changeConditionType = (item) => {
  item.data = [];
};




// 执行节点
const pushList = ref([{ type: 0, data: [] }])

const checkPass = () => {
  let { selectAllProject, bizProjectIdList } = pushCondition.value
  if (!selectAllProject && selectAllProject !== false) {
    message.warning('请选择项目类型')
    return false
  }
  if (selectAllProject !== true && !bizProjectIdList.length) {
    message.warning('请选择项目')
    return false
  }

  let idx = conditionList.value.findIndex(e => {
    if (!e.type) {
      message.warning('请选择告警条件')
      return true
    }
    if (!e.data.length) {
      message.warning('请选择告警条件值')
      return true
    }
    return false
  })
  if (idx !== -1) {
    return false
  }

  let idx2 = pushList.value.findIndex(e => {
    if (!e.type && e.type !== 0) {
      message.warning('请选择执行动作')
      return true
    }
    if (!e.data.length) {
      message.warning('请选择推送用户')
      return true
    }
  })
  if (idx2 !== -1) {
    return false
  }
  return true
}
const handleSave = () => {
  if (!checkPass()) {
    return
  }
  let m = {
    id: route.params.id,
    ...pushCondition.value,
    conditionList: conditionList.value,
    pushList: pushList.value,
  };
  loading.value = true
  apiAlarmPush.saveDetail(m).then(() => {
    message.success("保存成功");
  }).finally(() => {
    loading.value = false
  });
};
</script>
<style lang="less" scoped>
.common-wrapper {
  position: relative;
}

.common-wrapper-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-left: 10px;

  .title {
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    color: #38404c;
  }

  .content {
    font-size: 14px;
    line-height: 24px;
    color: #666666;
  }

  .ant-divider-vertical {
    margin: 0 12px;
  }
}

.ant-divider-horizontal {
  margin: 16px 0 0 0;
}

.node {
  position: relative;
  padding-left: 22px;

  &::before {
    content: "";
    position: absolute;
    top: 20px;
    left: 0;
    width: 10px;
    height: 10px;
    border: 2px solid #0256ff;
    border-radius: 50%;
  }

  &::after {
    content: "";
    position: absolute;
    top: 30px;
    left: 4px;
    width: 1px;
    height: calc(100% - 10px);
    background-color: #ebebeb;
  }

  .title {
    padding-top: 8px;
    line-height: 38px;
  }

  .content {
    position: relative;
    margin-bottom: 12px;
    padding: 8px 20px 20px;
    background-color: #f6f6f6;
    border-radius: 4px;
  }

  .delete-btn {
    position: absolute;
    right: 9px;
    top: 9px;
    width: 16px;
    height: 16px;
    background: #acaebc;
    border-radius: 50%;
    text-align: center;
    line-height: 14px;
    color: #fff;
    cursor: pointer;
  }
}

.node:last-child {
  &::after {
    height: 0;
  }
}

.ant-select,
.ant-input,
.ant-picker,
.ant-input-number {
  margin-top: 12px;
  margin-right: 12px;
  width: 146px;
}

.spin {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
}

.ant-spin {
  position: absolute;
  left: 50%;
  top: 50%;
}
</style>
